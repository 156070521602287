.input-list {
  width: 100%;
  position: relative;
  border-radius: 15px;
  background-color: var(--color-block);
  transition: box-shadow .2s linear;
  input {
    border-radius: 15px;
    width: 100%;
  }
  .input-list__input {
    display: flex;
    align-items: center;
  }
  .input-list__data {
    width: 100%;
    max-height: 400px;
    overflow: auto;
    background-color: var(--color-block);
    z-index: 100;

    display: none;
    flex-direction: column;
    gap: 10px;
    button {
      text-align: left;
      background-color: transparent;
      &:hover {
        color: var(--color-accent);
      }
    }
  }
  .input-list__clear {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    background-color: transparent;
    svg {
      width: inherit;
      height: inherit;
    }
  }
}

.input-list--active {
  box-shadow: 0 0 15px var(--color-translucent-15-accent);
  border-radius: 15px 15px 0px 0px;
  .input-list__data {
    padding: 10px;
    position: absolute;
    border-radius: 0px 0px 15px 15px;

    display: flex;
  }
}