.preview {
  display: flex;
  flex-direction: column;
  .preview__map {
    margin-top: 10px;
  }
  .preview__phone {
    margin-top: 10px;

    display: flex;
    span {
      font-size: 90%;
    }
    a {
      margin-left: 10px;
      margin-right: 5px;
  
      text-decoration: none;
      color: var(--color-accent);
      font-size: 85%;
    }
  }
  .preview__social {
    svg {
      zoom: 0.4;
      fill: var(--color-accent);
    }
  }
}

@media(max-width: 900px) {
  .preview {
    .preview__map {
      display: none;
    }
    .preview__phone {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .preview__social {
      margin-top: 20px;
      
      display: flex;
      gap: 20px;
      svg {
        zoom: 0.6;
      }
    }
  }
}