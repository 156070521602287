html {
  --color-green: #2F9A2F;
  --color-red: #c74444;
  --color-yellow: #C0A03A;
  
  --color-white: #fff;
  --color-black: #2b2c46;

  --color-gray: #EBEBEB;
  --color-dark-gray: #E0E0E0;

  --color-background: #f3f3fc;// #07061e;
  --color-text: #2b2c46; //#d1d2e3;
  --color-block: #f9f9fd; //#090a22;

  --color-accent: #927FC4; //#745CB4;
  --color-second-accent: #629AEE;

  --color-translucent-15-red: rgba(199, 68, 68, 0.15);
  --color-translucent-15-yellow: rgba(216, 190, 86, 0.15);
  --color-translucent-15-green: rgba(43, 141, 43, 0.15);
  --color-translucent-15-second-accent: rgba(20, 69, 61, 0.15);
  --color-translucent-15-accent: rgba(89, 81, 145, 0.15);
}

html[theme="dark"] {
  --color-green: #7f9f90;
  --color-red: #DE463F;
  --color-yellow: #d8be56;
  
  --color-white: #fff;
  --color-black: #1F1F1F;

  --color-gray: #EBEBEB;
  --color-dark-gray: #E0E0E0;

  --color-background: #07061e;
  --color-text: #d1d2e3;
  --color-block: #0a092c;

  --color-accent: #745CB4;
  --color-second-accent: #629AEE;

  --color-translucent-15-red: rgba(239, 83, 80, 0.15);
  --color-translucent-15-yellow: rgba(216, 190, 86, 0.15);
  --color-translucent-15-green: rgba(76, 175, 79, 0.15);
  --color-translucent-15-second-accent: rgba(20, 69, 61, 0.15);
  --color-translucent-15-accent: rgba(89, 81, 145, 0.15);
}

// :root {
//   --color-green: #7f9f90;
//   --color-red: #DE463F;
//   --color-yellow: #d8be56;
  
//   --color-white: #fff;
//   --color-black: #1F1F1F;

//   --color-gray: #EBEBEB;
//   --color-dark-gray: #E0E0E0;

//   --color-background: #f3f3fc;// #07061e;
//   --color-text: #2b2c46; //#d1d2e3;
//   --color-block: #ebecfa; //#090a22;

//   --color-accent: #927FC4; //#745CB4;
//   --color-second-accent: #629AEE;

//   --color-translucent-15-red: rgba(239, 83, 80, 0.15);
//   --color-translucent-15-yellow: rgba(216, 190, 86, 0.15);
//   --color-translucent-15-green: rgba(76, 175, 79, 0.15);
//   --color-translucent-15-second-accent: rgba(20, 69, 61, 0.15);
//   --color-translucent-15-accent: rgba(89, 81, 145, 0.15);
// }