.cars-table {
  width: 100%;
  border-collapse: collapse; 
  img {
    height: 50px;
  }
  tr {
    border-bottom: 1px solid var(--color-background);
  }
  td {
    padding: 10px;
    text-align: start;
    &:first-child {
      width: 150px;
    }
    &:last-child {
      text-align: end;
    }
  }
  button {
    width: 150px;
    padding: 5px;
    border-radius: 15px;
    transition: all .2s linear;
  }
}

@media(max-width: 900px) {
  .cars-table {
    button {
      width: 80px;
    }
    span, strong, button {
      font-size: 80%;
    }
    td:first-child {
      display: none;
    }
  }
}