.order-summary {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.stats {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .stats__item {
    width: 75px;
    padding: 10px;
    border-radius: 15px;

    flex: 1;
    gap: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    strong {
      font-size: 70%;
    }
  }
}

.alert {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  span {
    font-size: 90%;
  }
  svg {
    zoom: 0.9;
  }
}

.order-info {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;
  span {
    font-size: 90%;
  }
}

.car-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .car-list__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    img {
      height: 50px;
    }
  }
  .car-list__title {
    display: flex;
    flex-direction: column;
    strong, span {
      font-size: 90%;
    }
  }
}

.order-form {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
  .order-form__row {
    width: 100%;

    display: flex;
    gap: 10px;
  }
  .order-form__input-list {
    margin-top: 3px;
    border: 1px solid var(--color-translucent-15-accent);
    background-color: var(--color-background);
    input {
      margin-top: 0px !important;
      border: none !important;
    }
    .input-list__data {
      outline: 1px solid var(--color-translucent-15-accent);
      background-color: var(--color-background);
    }
  }
  .order-form__input {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 3px;
    input {
      padding: 10px;
      border-radius: 15px;
      border: 1px solid var(--color-translucent-15-accent);
      background-color: var(--color-background);
    }
    span {
      font-size: 90%;
      margin-left: 3px;
    }
  }
  .order-form__submit {
    width: 100%;
    margin-top: 50px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      padding: 10px 30px;
      border-radius: 15px;
    }
  }
}

.order-contact {
  display: flex;
  flex-direction: column;
  .order-contact__social {
    margin-top: 7px;

    display: flex;
    justify-content: center;
    gap: 10px;
    svg {
      zoom: 0.4;
      fill: var(--color-accent);
    }
  }
}

@media(max-width: 900px) {
  .order-form {
    .order-form__row {
      flex-direction: column;
      gap: 15px;
    }
    .order-form__submit {
      flex-direction: column;
      button {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
  .order-contact .order-contact__social {
    gap: 15px;
    svg {
      zoom: 0.6;
    }
  }
}