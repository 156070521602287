$border-radius: 15px;

.order-preview {
  border: 2px solid var(--color-accent);

  border-radius: $border-radius;
  box-shadow: 0 0 15px var(--color-translucent-15-accent);
  background-color: var(--color-accent);

  display: flex;
  .order-preview__inputs {
    border-radius: $border-radius;

    display: flex;
    gap: 2px;
    flex-grow: 1;
    .order-preview__input {
      flex-grow: 1;
      background-color: var(--color-block);

      display: flex;
      align-items: center;
      &:first-child {
        border-radius: $border-radius 0px 0px $border-radius;
      }
      input {
        padding: 13px;
        flex: 1;
      }
    }
    .order-preview__persons, .order-preview__date {
      width: 60px;
    }
    .order-preview__persons {
      padding-right: 15px;
    }
  }
  .order-preview__submit {
    padding: 10px 20px;
    border-radius: 0px $border-radius $border-radius 0px;
    color: var(--color-white);
    background-color: transparent;
  }
}

@media(max-width: 900px) {
  .order-preview {
    flex-direction: column;
    .order-preview__inputs {
      flex-direction: column;
    }
    .order-preview__input {
      width: 100%;
      &:first-child {
        border-radius: $border-radius $border-radius 0px 0px !important;
      }
    }
    .order-preview__persons, .order-preview__date {
      width: 100% !important;
    }
  }
}