html {
  font-family: Jost;
  font-size: 1.1rem;

  background-color: var(--color-background);
  color: var(--color-text);
}

::selection {
  color: var(--color-white);
  background-color: var(--color-accent);
}

html.toggle-theme,
html.toggle-theme *,
html.toggle-theme *:before,
html.toggle-theme *:after {
  transition: background-color 400ms ease-in;
  transition-delay: 0;
}

input {
  color: var(--color-text);
  background-color: var(--color-block);
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

strong {
  font-size: 1.1rem;
}

span {
  font-size: 1.1rem;
}

.wrapper {
  width: 100%;
  height: 100vh;
}

.main {
  width: 100%;
  height: 100%;
}

.content {
  min-height: 100vh;
  padding-top: 60px;
}

.container {
  width: 1200px;
  margin: 0 auto;
}

.block-container {
  width: 1200px;
  margin: 0 auto;
  padding: 50px 0px;
}

.block {
  width: 100%;
  padding: 10px;
  border-radius: 15px;
  background-color: var(--color-block);
  margin-bottom: 20px;
}

.title {
  margin-bottom: 50px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  span {
    color: var(--color-accent);
  }
}

.constructor {
  padding: 20px;
  border-radius: 15px;
  background-color: var(--color-text);
  color: var(--color-white);


  display: flex;
  flex-direction: column;
  gap: 30px;
}

.help {
  margin-top: 20px;
}

@media(max-width: 1200px) {
  .container {
    width: 100%;
    padding: 0px 15px;
  }
  .block-container {
    width: 100%;
    padding: 30px 15px;
  }
}

@media((max-width: 900px)) {
  .content {
    padding-top: 0px;
  }
  .header {
    display: none;
  }
}