.split {
  width: 100%;

  display: flex;
  gap: 20px;
  .split--grow {
    flex: 1;
  }
  .split--fixed {
    min-width: 100px;
    flex-shrink: 0;
  }
}

@media(max-width: 900px) {
  .split {
    flex-direction: column;
  }
  .split--fixed {
    width: 100% !important;
  }
}

