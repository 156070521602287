.counters {
  width: 100%;

  display: flex;
  gap: 40px;
  justify-content: space-between;
  .counters__item {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .counters__item-title {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
    border-radius: 15px;
    color: var(--color-accent);
    background-color: var(--color-translucent-15-accent);
  
    display: flex;
    align-items: center;
    justify-content: center;
    strong {
      font-size: 170%;
    }
    svg {
      zoom: 1.3;
      fill: var(--color-accent);
    }
  }
}

@media((max-width: 900px)) {
  .counters {
    flex-direction: column;
  }
}