.header {
  width: 100%;
  height: 60px;
  position: fixed;
  z-index: 1000;
  background-color: var(--color-block);
  .header__content {
    height: 100%;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header__logo {
    display: flex;
    align-items: center;
    gap: 15px;
    svg {
      height: 35px;
    }
  }
  .header__nav {
    display: flex;
    gap: 40px;
    a {
      text-decoration: none;
      color: var(--color-text);
      transition: color 0.2s linear;
      &:hover {
        color: var(--color-accent);
      }
    }
  }
}