.car-routes {
  height: 500px;
  background-color: var(--color-block);
  padding: 15px;
  border-radius: 15px;
  // box-shadow: 0 0 15px var(--color-translucent-15-accent);

  display: flex;
  flex-direction: column;
  gap: 15px;
  .car-routes__content {
    height: 100%;
    overflow: auto;

    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .car-routes__item {
    height: 85px;
    padding: 0px 0px 20px 0px;
    border-bottom: 1px solid var(--color-translucent-15-accent);
    background-color: transparent;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    &:last-child {
      border-bottom: none;
    }
    span, strong {
      font-size: 90%;
    }
    svg {
      zoom: 0.8;
    }
  }
  .car-routes__column {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;
    &:first-child {
      align-items: flex-start;
    }
  }
  .car-routes__header {
    display: flex;
    align-items: center;
    gap: 10px;
    strong {
      color: var(--color-accent);
    }
    svg {
      fill: var(--color-accent);
    }
  }
}