.car-cards {
  width: 100%;

  display: flex;
  gap: 50px;
  .car-cards__item {
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0 0 5px var(--color-translucent-15-accent);
    text-decoration: none;
    transition: box-shadow .2s linear;
    color: var(--color-text);
    background-color: var(--color-block);

    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    &:hover {
      box-shadow: 0 0 5px var(--color-accent);
    }
    img {
      height: 130px;
      border-radius: 15px;
    }
  }
  .car-cards__item-header {
    width: 100%;
    margin: 10px 0px;

    display: flex;
    justify-content: space-between;
  }
  .car-cards__item-stats {
    display: flex;
    gap: 20px;
  }
  .car-cards__item-stat {
    display: flex;
    gap: 5px;
    align-items: center;
    svg {
      zoom: 0.85;
      fill: var(--color-text);
    }
    span {
      font-size: 100%;
    }
  }
  .car-cards__item-content {
    width: 100%;
    span {
      font-size: 90%;
    }
  }
}

@media((max-width: 900px)) {
  .car-cards {
    flex-direction: column;
    gap: 20px;
  }
}