.style--freeze-lite {
  color: var(--color-dark-gray);
}

.style--accent-lite {
  color: var(--color-accent);
}

.style--positive {
  color: var(--color-white);
  background-color: var(--color-green);
}

.style--accent {
  color: var(--color-white);
  background-color: var(--color-accent);
}

.style--negative {
  color: var(--color-white);
  background-color: var(--color-red);
}

.style--positive {
  color: var(--color-white);
  background-color: var(--color-green);
}

.style--fill-negative {
  fill: var(--color-red);
}

.style--fill-warning {
  fill: var(--color-yellow);
}

.style--fill-accent {
  fill: var(--color-accent);
}

.style--fill-text {
  fill: var(--color-text);
}

.style--warning-lite {
  color: var(--color-yellow);
}

.style--negative-lite {
  color: var(--color-red);
}

.style--translucent-15-accent {
  fill: var(--color-accent);
  color: var(--color-accent);
  background-color: var(--color-translucent-15-accent);
}

.style--translucent-15-negative {
  fill: var(--color-red);
  color: var(--color-red);
  background-color: var(--color-translucent-15-red);
}

.style--translucent-15-positive {
  fill: var(--color-green);
  color: var(--color-green);
  background-color: var(--color-translucent-15-green);
}