.footer {
  width: 100%;
  margin-top: 20px;
  color: var(--color-white);
  background-color: var(--color-black);
  .footer__content {
    padding-top: 100px;
    padding-bottom: 100px;

    display: flex;
    justify-content: space-between;
  }
  .footer__logo {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    strong {
      margin-top: 9px;
      margin-bottom: 30px;
    }
    svg {
      width: 45px;
      fill: var(--color-white);
    }
  }
  .footer__logo-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .footer__contact {
    a {
      font-size: 80%;
      text-decoration: none;
      color: var(--color-white);
    }
  }
  .footer__social { 
    display: flex;
    gap: 20px;
    a {
      width: 20px;
      height: 20px;
      svg {
        width: inherit;
        height: inherit;
      }
    }
  }
  .footer__nav {
    display: flex;
    gap: 40px;
    a {
      text-decoration: none;
      color: var(--color-white);
      transition: color 0.2s linear;
      &:hover {
        color: var(--color-accent);
      }
    }
  }
  .footer__links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
  .footer__copyright {
    span {
      font-size: 80%;
    }
  }
}

@media (max-width: 900px) {
  .footer {
    .footer__content {
      padding-top: 50px;
      padding-bottom: 100px;
    }
    .footer__logo {
      margin-bottom: 50px;

      flex-direction: column;
      align-items: flex-start;
    }
    .footer__logo-text, .footer__contact, .footer__social {
      align-items: flex-start;
    }
    .footer__logo-text strong {
      margin-bottom: 0px;
    }
    .footer__content {
      flex-direction: column;
      align-items: flex-start;
    }
    .footer__links {
      align-items: flex-start;
      gap: 30px;
    }
    .footer__nav {
      gap: 20px;
      flex-direction: column;
    }
  }
}