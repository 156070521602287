.carousel {
  width: 100%;
  position: relative;
  &:hover .carousel__btns button {
    padding: 10px;
    background-color: var(--color-block);
    box-shadow: 0 0 5px var(--color-translucent-15-accent);
  }
  .carousel__image {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 90%;
      max-height: 90%;
    }
  }
  .carousel__btns {
    width: 100%;
    height: 50px;
    position: absolute;
    top: calc(50% - 25px);
    z-index: 100;

    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      transition: all .1s linear;
      border-radius: 50px;
      background-color: transparent;

      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        box-shadow: 0 0 5px var(--color-accent);
      }
    }
  }
}

@media(max-width: 900px) {
  .carousel .carousel__btns button {
    padding: 10px;
    background-color: var(--color-block);
    box-shadow: 0 0 5px var(--color-translucent-15-accent);
  }
}