.cars-switch {
  width: 100%;

  display: flex;
  gap: 20px;
  .cars-switch__item  {
    padding: 10px;
    border-radius: 15px;
    background-color: var(--color-block);
    box-shadow: 0 0 5px var(--color-translucent-15-accent);
    transition: box-shadow .2s linear;

    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    img {
      height: 100px;
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

.cars-switch--select {
  box-shadow: 0 0 5px var(--color-accent) !important;
}

@media(max-width: 900px) {
  .cars-switch .cars-switch__item {
    strong {
      font-size: 80%;
    }
    img {
      height: 35px;
      max-width: 70px;
    }
  }
}