.answers {
  width: 80%;
  margin-top: 20px;
  padding-bottom: 200px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  .answers__list {
    width: 100%;
    input {
      padding: 10px;
    }
  }
  span {
    font-size: 95%;
  }
}

@media(max-width: 900px) {
  .answers {
    width: 100%;
    padding-bottom: 30px;
  }
}