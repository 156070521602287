.mobile-menu {
  width: 100%;
  padding: 0 20px;
  height: 65px;
  background-color: var(--color-block);
  box-shadow: 0 0 5px var(--color-translucent-15-accent);
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  
  display: none;
  align-items: center;
  justify-content: space-between;
  .mobile-menu__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    a {
      text-decoration: none;
      font-size: 80%;
      color: var(--color-text);
    }
    svg {
      fill: var(--color-text);
    }
  }
}

@media(max-width: 900px) {
  .mobile-menu {
    display: flex;
  }
}