.car-about {
  height: 400px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  .car-about__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  .car-about__spec {
    display: flex;
    align-items: center;
    gap: 10px;
    svg {
      zoom: 0.6;
      fill: var(--color-text);
    }
  }
  .car-about__btn {
    width: 100%;
    padding: 10px;
    border-radius: 15px;
    text-decoration: none;
    text-align: center;
  }
}